import React, { createContext, useContext, useReducer } from "react";
import { ManageAlertsService, AlertsService } from "../services";
import { useAlertsState, useAlertsDispatch } from "./alertsProvider";

const ManageAlertsState = createContext();
const ManageAlertsDispatch = createContext();
const EVENT_TYPES = {
  UPDATE_ALERTS_FIELD: "update_alerts_field",
  UPDATE_ALERTS_FIELD_LOADING: "update_alerts_field_loading",
  UPDATE_ALERTS_FIELD_SUCCESS: "update_alerts_field_success",
  TOGGLE_ENABLE_ALERT_LOADING: "toggle_enable_alert_loading",
  TOGGLE_ENABLE_ALERT_SUCCESS: "toggle_enable_alert_success",
  INITIAL_UPDATE: "initial_update",
  INITIAL_UPDATE_LOADING: "initial_update_loading",
  INITIAL_UPDATE_SUCCESS: "initial_update_success",
  NEW_ALERTS_LOADING: "new_alerts_loading",
  NEW_ALERTS_SUCCESS: "new_alerts_success",
  SAVE_ALERTS_LOADING: "save_alerts_loading",
  SAVE_ALERTS_SUCCESS: "SAVE_alerts_SUCCESS",
  DELETE_ALERTS_LOADING: "delete_alerts_loading",
  DELETE_ALERTS_SUCCESS: "delete_alerts_success",
  CLEAR_MANAGE_ALERTS_ERRORS: "clear_manage_alerts_errors",
  MANAGE_ALERTS_ERROR: "manage_alerts_error",
  CLEAR_ALL: "clear_all",
};
const EVENTS = {
  [EVENT_TYPES.UPDATE_ALERTS_FIELD]: (state, event) => {
    const { name, value } = event.payload;
    return {
      ...state,
      [name]: value,
    };
  },
  [EVENT_TYPES.UPDATE_ALERTS_FIELD_LOADING]: (state) => {
    return {
      ...state,
      updateAlertsFieldLoading: true,
      updateAlertsFieldSuccess: false,
    };
  },
  [EVENT_TYPES.UPDATE_ALERTS_FIELD_SUCCESS]: (state) => {
    return {
      ...state,
      updateAlertsFieldLoading: false,
      updateAlertsFieldSuccess: true,
    };
  },
  [EVENT_TYPES.TOGGLE_ENABLE_ALERT_LOADING]: (state, event) => {
    const { alertId } = event.payload;
    return {
      ...state,
      toggleEnableAlertLoading: true,
      toggleEnableAlertSuccess: false,
      toggleEnableAlertId: alertId,
    };
  },
  [EVENT_TYPES.TOGGLE_ENABLE_ALERT_SUCCESS]: (state, event) => {
    const { alertId } = event.payload;
    return {
      ...state,
      toggleEnableAlertLoading: false,
      toggleEnableAlertSuccess: true,
      toggleEnableAlertId: alertId,
    };
  },
  [EVENT_TYPES.INITIAL_UPDATE]: (state, event) => {
    const { values } = event.payload;
    return {
      ...state,
      alertId: values.row.id,
      alertType: values.row.alertType,
      title: values.row.title,
      summary: values.row.summary,
      content: values.row.content,
      audienceRoles: values.row.audienceRoles,
      severity: values.row.severity,
      tags: values.row.tags,
      popupDuration: values.row.popupDuration,
      notifyDate: values.row.realNotifyDate,
      expireDate: values.row.realExpireDate,
      requestedBy: values.row.requestedBy,
      notifyExpireDateRange: values.row.notifyExpireDateRange,
      isEnabled: values.row.isEnabled,
    };
  },
  [EVENT_TYPES.INITIAL_UPDATE_LOADING]: (state) => {
    return {
      ...state,
      initialUpdateLoading: true,
      initialUpdateSuccess: false,
    };
  },
  [EVENT_TYPES.INITIAL_UPDATE_SUCCESS]: (state) => {
    return {
      ...state,
      initialUpdateLoading: false,
      initialUpdateSuccess: true,
    };
  },

  [EVENT_TYPES.NEW_ALERTS_LOADING]: (state) => {
    return {
      ...state,
      newAlertsSuccess: false,
      newAlertsLoading: true,
      manageAlertsError: false,
    };
  },
  [EVENT_TYPES.SAVE_ALERTS_LOADING]: (state) => {
    return {
      ...state,
      saveAlertsSuccess: false,
      newAlertsSuccess: false,
      saveAlertsLoading: true,
    };
  },
  [EVENT_TYPES.DELETE_ALERTS_LOADING]: (state, event) => {
    const { alertId } = event.payload;
    return {
      ...state,
      deleteAlertsSuccess: false,
      deleteAlertsLoading: true,
      manageAlertsError: false,
      deleteAlertsId: alertId,
    };
  },
  [EVENT_TYPES.DELETE_ALERTS_SUCCESS]: (state) => {
    return {
      ...state,
      deleteAlertsSuccess: true,
      deleteAlertsLoading: false,
      manageAlertsError: false,
      deleteAlertsId: null,
    };
  },
  [EVENT_TYPES.NEW_ALERTS_SUCCESS]: (state) => {
    return {
      ...state,
      newAlertsSuccess: true,
      newAlertsLoading: false,
      manageAlertsError: false,
    };
  },
  [EVENT_TYPES.SAVE_ALERTS_SUCCESS]: (state) => {
    return {
      ...state,
      saveAlertsSuccess: true,
      newAlertsSuccess: false,
      saveAlertsLoading: false,
    };
  },
  [EVENT_TYPES.MANAGE_ALERTS_ERROR]: (state, event) => {
    const { error } = event.payload;
    return {
      ...state,
      error,
      saveAlertsSuccess: false,
      newAlertsSuccess: false,
      saveAlertsLoading: false,
    };
  },
  [EVENT_TYPES.CLEAR_MANAGE_ALERTS_ERRORS]: (state) => {
    return {
      ...state,
      manageAlertsError: "",
      saveAlertsSuccess: false,
      newAlertsSuccess: false,
      saveAlertsLoading: false,
    };
  },
  [EVENT_TYPES.CLEAR_ALL]: (state) => {
    //console.log("Clear all triggered");
    return {
      ...state,
      alertId: "",
      alertType: [],
      title: "",
      summary: "",
      content: "",
      audienceRoles: [],
      severity: "",
      tags: [],
      popupDuration: 3000,
      notifyDate: "",
      expireDate: "",
      requestedBy: "",
      notifyExpireDateRange: "",
      isEnabled: true,
      manageAlertsError: "",
      saveAlertsSuccess: false,
      saveAlertsLoading: false,
      deleteAlertsSuccess: false,
      deleteAlertsLoading: false,
      deleteAlertsId: null,
      newAlertsLoading: false,
      newAlertsSuccess: false,
      updateAlertsFieldLoading: false,
      updateAlertsFieldSuccess: false,
      toggleEnableAlertLoading: false,
      toggleEnableAlertSuccess: false,
      initialUpdateLoading: false,
      initialUpdateSuccess: false,
      toggleEnableAlertId: null,
    };
  },
};

const INITIAL_STATE = {
  alertId: "",
  alertType: [],
  title: "",
  summary: "",
  content: "",
  audienceRoles: [],
  severity: "",
  tags: [],
  popupDuration: 3000,
  notifyDate: "",
  expireDate: "",
  requestedBy: "",
  notifyExpireDateRange: "",
  isEnabled: true,
  manageAlertsError: "",
  saveAlertsSuccess: false,
  saveAlertsLoading: false,
  deleteAlertsLoading: false,
  deleteAlertsSuccess: false,
  deleteAlertsId: null,
  newAlertsLoading: false,
  newAlertsSuccess: false,
  updateAlertsFieldLoading: false,
  updateAlertsFieldSuccess: false,
  toggleEnableAlertLoading: false,
  toggleEnableAlertSuccess: false,
  initialUpdateLoading: false,
  initialUpdateSuccess: false,
  toggleEnableAlertId: null,
};

const ManageAlertsReducer = (state, event) => {
  return EVENTS[event.type](state, event) || state;
};

const ManageAlertsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ManageAlertsReducer, INITIAL_STATE);

  const handleUpdate = (event) => {
    handleClearErrors();
    const name = event.target.name;
    const value = event.target.value;

    dispatch({ type: EVENT_TYPES.UPDATE_ALERTS_FIELD, payload: { name, value } });
  };
  const handleClearAll = (event) => {
    dispatch({ type: EVENT_TYPES.CLEAR_ALL });
  };

  const handleInitialUpdate = (values) => {
    dispatch({ type: EVENT_TYPES.INITIAL_UPDATE_LOADING });
    handleClearErrors();
    dispatch({ type: EVENT_TYPES.INITIAL_UPDATE, payload: { values } });
    // setTimeout(() => {
    dispatch({ type: EVENT_TYPES.INITIAL_UPDATE_SUCCESS });
    // }, 1000);
  };

  const handleSaveExistingAlerts = (requestObj) => {
    const {
      alertId,
      alertType,
      title,
      summary,
      content,
      audienceRoles,
      severity,
      tags,
      popupDuration,
      notifyDate,
      expireDate,
      requestedBy,
      isEnabled,
    } = state;
    dispatch({ type: EVENT_TYPES.SAVE_ALERTS_LOADING });
    ManageAlertsService.saveAlert(requestObj)
      .then(() => {
        dispatch({ type: EVENT_TYPES.SAVE_ALERTS_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.MANAGE_ALERTS_ERROR,
          payload: {
            manageAlertsError: message,
          },
        });
      });
  };
  const handleAddAlerts = (requestObj) => {
    const {
      alertId,
      alertType,
      title,
      summary,
      content,
      audienceRoles,
      severity,
      tags,
      popupDuration,
      notifyDate,
      expireDate,
      requestedBy,
      isEnabled,
    } = state;
    dispatch({ type: EVENT_TYPES.NEW_ALERTS_LOADING });
    ManageAlertsService.addAlert(requestObj)
      .then(() => {
        dispatch({ type: EVENT_TYPES.NEW_ALERTS_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.MANAGE_ALERTS_ERROR,
          payload: {
            manageAlertsError: message,
          },
        });
      });
  };
  const handleDeleteAlerts = (alertId) => {
    dispatch({ type: EVENT_TYPES.DELETE_ALERTS_LOADING, payload: { alertId } });
    ManageAlertsService.deleteAlert(alertId)
      .then(() => {
        dispatch({ type: EVENT_TYPES.DELETE_ALERTS_SUCCESS });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.MANAGE_ALERTS_ERROR,
          payload: {
            manageAlertsError: message,
          },
        });
      });
  };
  const handleToggleDeleteAlertLoading = (isLoading = false) => {
    if (isLoading) {
      dispatch({ type: EVENT_TYPES.DELETE_ALERTS_LOADING });
    } else {
      dispatch({ type: EVENT_TYPES.CLEAR_ALL });
    }
  };
  const handleToggleEnableAlert = (alertId) => {
    dispatch({ type: EVENT_TYPES.TOGGLE_ENABLE_ALERT_LOADING, payload: { alertId } });
    ManageAlertsService.toggleEnableAlert(alertId)
      .then(() => {
        dispatch({ type: EVENT_TYPES.TOGGLE_ENABLE_ALERT_SUCCESS, payload: { alertId } });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.MANAGE_ALERTS_ERROR,
          payload: {
            manageAlertsError: message,
          },
        });
      });
  };
  const handleError = (errorMsg) => {
    dispatch({
      type: EVENT_TYPES.MANAGE_ALERTS_ERROR,
      payload: {
        manageAlertsError: errorMsg,
      },
    });
  };
  const handleClearErrors = () => {
    dispatch({ type: EVENT_TYPES.CLEAR_MANAGE_ALERTS_ERRORS });
  };

  const events = {
    onUpdate: handleUpdate,
    onInitialUpdate: handleInitialUpdate,
    onError: handleError,
    onSaveExistingAlerts: handleSaveExistingAlerts,
    onAddAlerts: handleAddAlerts,
    onDeleteAlerts: handleDeleteAlerts,
    onToggleDeleteAlertLoading: handleToggleDeleteAlertLoading,
    onToggleEnableAlert: handleToggleEnableAlert,
    onClearErrors: handleClearErrors,
    onClearAll: handleClearAll,
  };

  return (
    <ManageAlertsState.Provider value={state}>
      <ManageAlertsDispatch.Provider value={events}>{children}</ManageAlertsDispatch.Provider>
    </ManageAlertsState.Provider>
  );
};

const useManageAlertsState = () => {
  const context = useContext(ManageAlertsState);

  if (context === undefined) {
    throw new Error("useManageAlertsState must be used within an ManageAlertsProvider");
  }

  return context;
};

const useManageAlertsDispatch = () => {
  const context = useContext(ManageAlertsDispatch);

  if (context === undefined) {
    throw new Error("useManageAlertsDispatch must be used within an ManageAlertsProvider");
  }

  return context;
};

export { ManageAlertsProvider, useManageAlertsState, useManageAlertsDispatch };
