import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import { Theme } from "../../../theme";
import { useFormik } from "formik";
import * as yup from "yup";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import PinDropIcon from "@mui/icons-material/PinDrop";
import ContactForm from "../../ContactForm";
import { Helmet } from "react-helmet";
import { ContactProvider } from "../../../providers/contactProvider";
import DownloadIcon from "@mui/icons-material/Download";
import Breadcrumbs from "../../Breadcrumbs";
import styles from "../pages.module.scss";

const css = {
  sectionHeading: {
    fontWeight: "600",
    marginBottom: "4rem",
    position: "relative",
    textAlign: "center",
    "&:before": {
      display: "inline-block",
      content: "' '",
      position: "absolute",
      height: "2px",
      width: "9rem",
      backgroundColor: "#ef4720",
      borderRadius: ".4rem",
      //borderBottom: "2px solid #ef4720",
      bottom: "-.5rem",
      margin: "0 1rem",
      textAlign: "center",
      // left: "50%",
      // marginLeft: "-50%",
      lineHeight: "2rem",
    },
  },
  subHeading: {
    textAlign: "center",
    margin: "2rem auto 6rem auto",
  },
  mediaImg: {
    width: "2rem",
    height: "2rem",
    marginRight: "1rem",
    marginTop: "-.2rem",
    color: "#ef4720",
  },
  mediaBody: {
    fontSize: "1rem",
  },
  mediaLink: {
    fontWeight: "600",
    textDecoration: "none",
  },
  accentHeading: {
    fontSize: "2rem",
    color: "#2c3657",
    fontWeight: "600",
    lineHeight: "1.2",
    marginBottom: "1rem",
  },
  phoneNum: {
    display: "block",
  },
};
const DownloadHir = () => {
  return (
    <ContactProvider>
      <ThemeProvider theme={Theme}>
        <Helmet>
          <title>IHSI - Download HIR</title>
        </Helmet>
        <div className={styles.darkSection}>
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                {/* <Breadcrumbs
                  isDarkMode={true}
                  linksArray={[
                    {
                      linkLabel: "Home",
                      linkUrl: "/",
                    },
                    {
                      linkLabel: "Download HIR",
                      linkUrl: "/downloadhir",
                    },
                  ]}
                /> */}
              </div>
            </div>
            <div className={styles.mainContent}>
              <div className={"row center-xs"}>
                <div className="col-xs-12">
                  <div className={styles.disclaimerBox}>
                    <DownloadIcon sx={{ fontSize: "6rem", color: "#FFF", marginTop: "5rem" }} size="large" />
                    <Typography sx={(css.subHeading, { color: "#FFF", marginTop: "3rem" })} variant="h5">
                      Your HIR is downloading now.
                    </Typography>
                  </div>
                </div>
                <div className="col-xs-12">
                  <div className={styles.disclaimerBox}>
                    <Typography
                      sx={(css.subHeading, { color: "#FFF", marginTop: "4rem", display: "block" })}
                      variant="p"
                    >
                      Please close this tab to continue.
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeProvider>
    </ContactProvider>
  );
};

export default DownloadHir;
