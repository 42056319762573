import axios from "axios";
import { fetchWithTimeout } from "./serviceHelpers";
import { adjustDates } from "../utils/filterHelpers";
import moment from "moment";
import { formControlClasses } from "@mui/material";

const AuthService = {
  formatDate(date) {
    if (date === null || date === undefined || date === "") {
      return "";
    } else {
      const dateValue = new Date(date);

      const day = dateValue.getUTCDate();
      const month = dateValue.getUTCMonth() + 1; // Return Value is 0 indexed
      const year = dateValue.getUTCFullYear();

      const formattedDate = new Date(`${month}-${day}-${year}`);
      return moment(formattedDate).format("MMM Do YYYY").toString();
    }
  },
  _url: `${process.env.REACT_APP_API_URL || ""}/api`,

  _token(token) {
    if (typeof token !== "undefined") {
      localStorage.setItem("token", token);
      return;
    }
    return localStorage.getItem("token");
  },
  _user(user) {
    if (typeof user !== "undefined") {
      user.roles = user.roles.map((item) => item.toLowerCase());
      localStorage.setItem("user", JSON.stringify(user));
      return;
    }
    return localStorage.getItem("user");
  },
  _userActivity(data) {
    if (typeof data !== "undefined") {
      localStorage.setItem("userActivity", JSON.stringify(data));
      return;
    }
    return localStorage.getItem("userActivity");
  },

  _userPreferences(data) {
    if (typeof data !== "undefined") {
      localStorage.setItem("userPreferences", JSON.stringify(data));
      return;
    }
    return localStorage.getItem("userPreferences");
  },
  _searchRequest(req) {
    if (typeof req !== "undefined") {
      localStorage.setItem("searchRequest", JSON.stringify(req));

      return;
    }
    return localStorage.getItem("searchRequest");
  },
  _facetRequest(req) {
    if (typeof req !== "undefined") {
      localStorage.setItem("facetRequest", JSON.stringify(req));

      return;
    }
    return localStorage.getItem("facetRequest");
  },
  _recordData(data) {
    if (typeof data !== "undefined") {
      localStorage.setItem("recordData", JSON.stringify(data));
      return;
    }
    return localStorage.getItem("recordData");
  },
  authHeader() {
    let user = this._user();
    if (user && user.token) {
      return { Authorization: "Bearer " + user.token }; // for Spring Boot back-end
    } else {
      return {};
    }
  },

  isLoggedIn() {
    const res = !!(this._user() && this._token());

    return res;
  },
  async login(email, password) {
    try {
      let response = await fetchWithTimeout(`${this._url}/auth/signin`, {
        method: "POST",
        body: JSON.stringify({ email, password }),
        headers: {
          "content-type": "application/json",
        },
      });

      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }

      let data = await response.json();
      //localStorage.removeItem("userActivity");
      localStorage.removeItem("userActivity");
      localStorage.removeItem("searchData");
      localStorage.removeItem("searchRequest");
      localStorage.removeItem("facetRequest");
      localStorage.removeItem("recordData");
      localStorage.removeItem("exportItems");
      localStorage.removeItem("invitedUser");
      localStorage.removeItem("hirData");
      localStorage.removeItem("hirSearchData");
      localStorage.removeItem("hirSearchTopicData");
      localStorage.removeItem("exportItems");
      localStorage.removeItem("hirSearchRequest");
      localStorage.removeItem("hirSearchTopicRequest");
      this._token(data.token);
      this._user(data);
      return data;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },

  async logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("userActivity");
    localStorage.removeItem("searchData");
    localStorage.removeItem("token");
    localStorage.removeItem("searchRequest");
    localStorage.removeItem("facetRequest");
    localStorage.removeItem("recordData");
    localStorage.removeItem("exportItems");
    localStorage.removeItem("invitedUser");
    localStorage.removeItem("hirData");
    localStorage.removeItem("hirSearchData");
    localStorage.removeItem("exportItems");
    localStorage.removeItem("hirSearchRequest");
    localStorage.removeItem("hirPreviewRequest");
    localStorage.removeItem("hirTopicsRequest");
    localStorage.removeItem("hirDashboardTopicsRequest");
    localStorage.removeItem("searchRequest");
    localStorage.removeItem("userPreferences");
    localStorage.removeItem("clearNotifications");
    localStorage.removeItem("backButtonDetected");
    localStorage.removeItem("activeAlerts");
    localStorage.removeItem("alertsList");
    localStorage.removeItem("accountSettingsData");
    sessionStorage.removeItem("activeAlertsCheck");
    sessionStorage.removeItem("viewedAlertsCheck");
    sessionStorage.removeItem("alertTypes");
    await true;
    // return new Promise(function (resolve, reject) {
    //   resolve(true);
    // });
  },

  async getAllUserRoles() {
    try {
      let response = await fetchWithTimeout(`${this._url}/auth/getroles`, {
        method: "POST",
        //body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${this._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      return data;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        //let data = await response.json();
        //console.log(response);
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },
  async getIhsiUserReports(requestObj) {
    // if (requestObj === undefined) {
    //   const requestObj = {
    //     size: 100,
    //     from: 0,
    //     orderBy: "last_login_date",
    //     sortOrder: "desc",
    //     lastLoginMonth: 0,
    //     lastLoginWeek: 0,
    //   };
    // }

    try {
      let reqQuery = "";
      Object.keys(requestObj).forEach((item) => {
        if (item?.size) {
          reqQuery = reqQuery + `?Size=${item.size}`;
        }

        if (item?.from) {
          reqQuery = reqQuery + `&From=${item.from}`;
        }

        if (item?.orderBy) {
          reqQuery = reqQuery + `&OrderBy=${item.orderBy}`;
        }

        if (item?.sortOrder) {
          reqQuery = reqQuery + `&SortOrder=${item.sortOrder}`;
        }

        if (item?.deleted) {
          reqQuery = reqQuery + `&Deleted=${item.deleted}`;
        }
        if (item?.suspended) {
          reqQuery = reqQuery + `&Suspended=${item.suspended}`;
        }
        return item;
      });
      let response = await fetchWithTimeout(`${this._url}/report/usersreport${reqQuery}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${this._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();

      return data;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        //let data = await response.json();
        //console.log(response);
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },

  async getUserReports(requestObj) {
    // if (requestObj === undefined) {
    //   const requestObj = {
    //     size: 100,
    //     from: 0,
    //     orderBy: "last_login_date",
    //     sortOrder: "desc",
    //     lastLoginMonth: 0,
    //     lastLoginWeek: 0,
    //   };
    // }

    try {
      // let response = await fetchWithTimeout(
      //   `${this._url}/report/usersreport?Size=${requestObj.size}&From=${requestObj.from}&OrderBy=${requestObj.orderBy}&SortOrder=${requestObj.sortOrder}&LastLoginMonth=${requestObj.lastLoginMonth}&LastLoginWeek=${requestObj.lastLoginWeek}`,
      //   {
      //     method: "GET",
      //     headers: {
      //       "content-type": "application/json",
      //       authorization: `Bearer ${this._token()}`,
      //     },
      //   }
      // );
      let reqQuery = "";
      Object.keys(requestObj).forEach((item) => {
        if (item?.size) {
          reqQuery = reqQuery + `?Size=${item.size}`;
        }

        if (item?.from) {
          reqQuery = reqQuery + `&From=${item.from}`;
        }

        if (item?.orderBy) {
          reqQuery = reqQuery + `&OrderBy=${item.orderBy}`;
        }

        if (item?.sortOrder) {
          reqQuery = reqQuery + `&SortOrder=${item.sortOrder}`;
        }

        if (item?.deleted) {
          reqQuery = reqQuery + `&Deleted=${item.deleted}`;
        }
        if (item?.suspended) {
          reqQuery = reqQuery + `&Suspended=${item.suspended}`;
        }
        return item;
      });
      let response = await fetchWithTimeout(`${this._url}/auth/userslist${reqQuery}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${this._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();

      return data;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        //let data = await response.json();
        //console.log(response);
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },

  async getUserActivity(skip = 0, take = 10) {
    try {
      let response = await fetchWithTimeout(`${this._url}/userActivity/activitysummary?skip=${skip}&take=${take}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${this._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();

      if (data === null) {
        data.recentlyViewedRecords = [];
        data.recentSearches = [];
        data.recentlyUpdatedRecords = [];
      } else {
        if (data && data.recentlyViewedRecords === null) {
          data.recentlyViewedRecords = [];
        }
        if (data && data.recentlyUpdatedRecords === null) {
          data.recentlyUpdatedRecords = [];
        }
        if (data && data.recentSearches === null) {
          data.recentSearches = [];
        }
      }

      let finalData = data;

      let storedActivity = JSON.parse(this._userActivity());
      if (storedActivity) {
        if (storedActivity.recentlyViewedRecords && storedActivity.recentlyViewedRecords.length > 0) {
          let newArray = data.recentlyViewedRecords.filter(
            (item1) => !storedActivity.recentlyViewedRecords.some((item2) => item1.hssId === item2.hssId)
          );
          storedActivity.recentlyViewedRecords = [...storedActivity.recentlyViewedRecords, ...newArray];
          storedActivity.recentlyViewedRecords = storedActivity.recentlyViewedRecords.slice(0, 10);
          finalData.recentlyViewedRecords = storedActivity.recentlyViewedRecords;
        }

        if (storedActivity.recentSearches && storedActivity.recentSearches.length > 0) {
          let newArray = data.recentSearches.filter(
            (item1) => !storedActivity.recentSearches.some((item2) => item1.activityValue === item2.activityValue)
          );
          storedActivity.recentSearches = [...storedActivity.recentSearches, ...newArray];
          storedActivity.recentSearches = storedActivity.recentSearches.slice(0, 10);
          finalData.recentSearches = storedActivity.recentSearches;
        }
      }

      this._userActivity(finalData);

      return finalData;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        //let data = await response.json();
        //console.log(response);
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },

  async saveUserActivity(request, optionalData) {
    // Allowable types:
    // "bookmark", "search", "view", "substance", "savedsearch", "export"
    try {
      let response = await fetchWithTimeout(`${this._url}/userActivity/saveuseractivity`, {
        method: "POST",
        body: JSON.stringify(request),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${this._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }
      let data = await response.json();
      let finalActivityObj = {};
      let cachedActivity = JSON.parse(this._userActivity());
      let storedUserActivity = cachedActivity !== null ? cachedActivity : {};

      if (optionalData !== undefined && Object.keys(optionalData).length > 0) {
        if (request.activityType === "view") {
          // let currentUserActivityObj = {
          //   hssId: optionalData.hssId,
          //   highImpactReport: optionalData.highImpactReport,
          //   compound: optionalData.compound,
          //   organization: optionalData.organization,
          //   organizations: optionalData.organizations,
          //   atcTherapeuticAreaSubGroupName: optionalData.atcTherapeuticAreaSubGroupName,
          //   indication: optionalData.indication,
          //   meddraCode: optionalData.meddraCode,
          //   atcCode: optionalData.atcCode,
          //   basicForm1: optionalData.basicForm1,
          //   entityType: optionalData.entityType,
          //   updatedDate: optionalData.updatedDate,
          //   estimatedCost: optionalData.estimatedCost,
          //   recordStatus: optionalData.recordStatus,
          //   ihsiCategory: optionalData.ihsiCategory,
          //   viewCount: optionalData.viewCount,
          // };
          let currentUserActivityObj = { ...optionalData };
          if (
            storedUserActivity?.recentlyViewedRecords !== undefined &&
            storedUserActivity.recentlyViewedRecords.length > 0
          ) {
            let matchFound = storedUserActivity.recentlyViewedRecords.find((item) => item.hssId === optionalData.hssId);
            if (!matchFound) {
              storedUserActivity.recentlyViewedRecords.unshift(currentUserActivityObj);
              if (storedUserActivity.recentlyViewedRecords.length > 9) {
                storedUserActivity.recentlyViewedRecords.pop();
              }
              this._userActivity(storedUserActivity);
            }
          } else {
            storedUserActivity.recentlyViewedRecords = [];
            storedUserActivity.recentlyViewedRecords.push(currentUserActivityObj);
            this._userActivity(storedUserActivity);
          }
        }
      }

      if (request.activityType === "search") {
        let currentRecentSearchObj = {
          activityType: request.activityType,
          activityValue: request.activityValue,
          createdBy: JSON.parse(this._user()),
          createdDate: new Date().toISOString(),
          entityType: "user_activity",
        };
        if (storedUserActivity.recentSearches !== undefined && storedUserActivity.recentSearches.length > 0) {
          let matchFound = storedUserActivity.recentSearches.find(
            (item) => item.activityValue === request.activityValue
          );
          if (!matchFound) {
            storedUserActivity.recentSearches.unshift(currentRecentSearchObj);
            if (storedUserActivity.recentSearches.length > 9) {
              storedUserActivity.recentSearches.pop();
            }
            this._userActivity(storedUserActivity);
          }
        } else {
          storedUserActivity.recentSearches = [];
          storedUserActivity.recentSearches.push(currentRecentSearchObj);
          this._userActivity(storedUserActivity);
        }
      }
      return true;
    } catch (err) {
      if (err.name === "AbortError") {
        throw Error("Request timed out.");
      }
      if (err.message) {
        //let data = await response.json();
        //console.log(response);
        throw Error(err.message);
      }
      if (err.statusText) {
        throw Error(err.statusText);
      }
      throw Error("Request Timeout");
    }
  },

  async retrieveSearchResults(requestObj, referrer = "") {
    this._searchRequest(requestObj);
    let newRequestObj = {
      ...requestObj,
    };

    try {
      let response = await fetchWithTimeout(`${this._url}/search/search`, {
        method: "POST",
        body: JSON.stringify(newRequestObj),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${this._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }

      let data = await response.json();
      let newResponse = {
        ...data,
      };
      if (newResponse.originalRequest !== undefined || newResponse.originalRequest !== null) {
        if (
          newResponse.originalRequest.lastUpdatedDateFrom !== undefined &&
          newResponse.originalRequest.lastUpdatedDateFrom !== null &&
          newResponse.originalRequest.lastUpdatedDateFrom !== ""
        ) {
          newResponse.originalRequest.lastUpdatedDateFrom =
            newResponse.originalRequest.lastUpdatedDateFrom.split("T")[0];
        }

        if (
          newResponse.originalRequest.lastUpdatedDateTo !== undefined &&
          newResponse.originalRequest.lastUpdatedDateTo !== null &&
          newResponse.originalRequest.lastUpdatedDateTo !== ""
        ) {
          newResponse.originalRequest.lastUpdatedDateTo = newResponse.originalRequest.lastUpdatedDateTo.split("T")[0];
        }

        if (
          newResponse.originalRequest.emaTimelineFrom !== undefined &&
          newResponse.originalRequest.emaTimelineFrom !== null &&
          newResponse.originalRequest.emaTimelineFrom !== ""
        ) {
          newResponse.originalRequest.emaTimelineFrom = newResponse.originalRequest.emaTimelineFrom.split("T")[0];
        }

        if (
          newResponse.originalRequest.emaTimelineTo !== undefined &&
          newResponse.originalRequest.emaTimelineTo !== null &&
          newResponse.originalRequest.emaTimelineTo !== ""
        ) {
          newResponse.originalRequest.emaTimelineTo = newResponse.originalRequest.emaTimelineTo.split("T")[0];
        }
      }
      return newResponse;
    } catch (err) {
      throw new Error(err);
    }
  },

  async retrieveFacetResults(requestObj, referrer = "") {
    try {
      let response = await fetchWithTimeout(`${this._url}/search/getcachedfacets`, {
        method: "POST",
        body: JSON.stringify(requestObj),
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${this._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }

      let data = await response.json();
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },

  async getRecordDetails(hssIdInt, isPreview) {
    let apiUrl = isPreview
      ? `${this._url}/search/getkernelpreviewdetails?hssId=${hssIdInt}`
      : `${this._url}/search/getkerneldetails?hssId=${hssIdInt}`;

    try {
      let response = await fetchWithTimeout(apiUrl, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${this._token()}`,
        },
      });
      if (!response.ok) {
        if (response.status === 429) {
          throw Error(response.statusText);
        } else if (response.status === 403) {
          throw Error(response.statusText);
        }
        let data = await response.json();
        throw new Error(data.responseCode);
      }

      let data = await response.json();
      // data.contentPendingReviewFields.forEach((item) => {
      //   return item
      //     .replace(/\s+/g, " ")
      //     .trim()
      //     .replace(/^\w/, (c) => c.toLowerCase());
      // });
      this._recordData(data);
      return data;
    } catch (err) {
      throw new Error(err);
    }
  },
  saveUserPreferences(data) {
    this._userPreferences(data);
  },
  getUserPreferences() {
    let data = JSON.parse(localStorage.getItem("userPreferences"));
    if (data === null) {
      data = {
        toggleView: "cardView",
      };
      this._userPreferences(data);
    }
    return data;
  },
  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  },
  getToken() {
    return JSON.parse(localStorage.getItem("token"));
  },
  setCurrentSearchRequest(req) {
    this._searchRequest(req);
  },
  getCurrentSearchRequest() {
    return JSON.parse(localStorage.getItem("searchRequest"));
  },
  setCurrentFacetRequest(req) {
    this._facetRequest(req);
  },
  getCurrentFacetRequest() {
    return JSON.parse(localStorage.getItem("facetRequest"));
  },
};

export default AuthService;
