import React, { createContext, useContext, useReducer } from "react";
import { AlertsService } from "../services";
import { updateCachedActiveAlerts } from "../utils/activeAlertsHelper";
const AlertsState = createContext();
const AlertsDispatch = createContext();

const EVENT_TYPES = {
  GET_ALERT_TYPES_LOADING: "get_alert_types_loading",
  GET_ALERT_TYPES_SUCCESS: "get_alert_types_success",
  VIEWED_ALERTS_UPDATE: "viewed_alerts_update",
  VIEWED_ALERTS_LOADING: "viewed_alerts_loading",
  VIEWED_ALERTS_SUCCESS: "viewed_alerts_success",
  ALERTS_LIST_SUCCESS: "alerts_list_success",
  ALERTS_LIST_LOADING: "alerts_list_loading",
  ALERTS_LIST_ERROR: "alerts_list_error",
  ALERTS_LIST_BY_TYPE_SUCCESS: "alerts_list_by_type_success",
  ALERTS_LIST_BY_TYPE_LOADING: "alerts_list_by_type_loading",
  GET_ALERT_LOADING: "get_alert_loading",
  GET_ALERT_SUCCESS: "get_alert_success",
  ACTIVE_ALERTS_LOADING: "active_alerts_loading",
  ACTIVE_ALERTS_SUCCESS: "active_alerts_success",
  ACTIVE_ALERTS_ERROR: "active_alerts_error",
  CLEAR_ERRORS: "clear_errors",
  ERROR: "error",
};

const EVENTS = {
  [EVENT_TYPES.GET_ALERT_TYPES_LOADING]: (state) => {
    return {
      ...state,
      getAlertTypesLoading: true,
      getAlertTypesSuccess: false,
    };
  },
  [EVENT_TYPES.GET_ALERT_TYPES_SUCCESS]: (state, event) => {
    const { getAlertTypesData } = event.payload;
    return {
      ...state,
      getAlertTypesLoading: false,
      getAlertTypesSuccess: true,
      getAlertTypesData: getAlertTypesData,
    };
  },
  [EVENT_TYPES.VIEWED_ALERTS_UPDATE]: (state, event) => {
    const { updatedViewedAlertsList } = event.payload;
    return {
      ...state,
      viewedAlertsData: updatedViewedAlertsList,
    };
  },
  [EVENT_TYPES.VIEWED_ALERTS_LOADING]: (state) => {
    return {
      ...state,
      viewedAlertsLoading: true,
      viewedAlertsSuccess: false,
    };
  },
  [EVENT_TYPES.VIEWED_ALERTS_SUCCESS]: (state, event) => {
    const { viewedAlertsData } = event.payload;
    return {
      ...state,
      viewedAlertsLoading: false,
      viewedAlertsSuccess: true,
      viewedAlertsData: viewedAlertsData,
    };
  },
  [EVENT_TYPES.ALERTS_LIST_SUCCESS]: (state, event) => {
    const { newData } = event.payload;

    return {
      ...state,
      alertsListSuccess: true,
      alertsListData: newData,
      alertsListLoading: false,
    };
  },
  [EVENT_TYPES.ALERTS_LIST_LOADING]: (state) => {
    return {
      ...state,
      alertsListLoading: true,
      alertsListSuccess: false,
      error: "",
    };
  },
  [EVENT_TYPES.ALERTS_LIST_ERROR]: (state, event) => {
    const { error } = event.payload;
    return {
      ...state,
      error: "",
      alertsListError: error,
      activeAlertsError: "",
      alertsListSuccess: false,
      alertsListLoading: false,
      activeAlertsSuccess: false,
      activeAlertsLoading: false,
      viewedAlertsLoading: false,
      viewedAlertsSuccess: false,
      getAlertTypesLoading: false,
      getAlertTypesSuccess: false,
      getAlertTypesData: [],
    };
  },
  [EVENT_TYPES.ALERTS_LIST_BY_TYPE_SUCCESS]: (state, event) => {
    const { newData } = event.payload;

    return {
      ...state,
      alertsListByTypeSuccess: true,
      alertsListByTypeData: newData,
      alertsListByTypeLoading: false,
    };
  },
  [EVENT_TYPES.ALERTS_LIST_BY_TYPE_LOADING]: (state, event) => {
    return {
      ...state,
      alertsListByTypeLoading: true,
      alertsListByTypeSuccess: false,
      error: "",
    };
  },
  [EVENT_TYPES.GET_ALERT_LOADING]: (state) => {
    return {
      ...state,
      getAlertLoading: true,
      getAlertSuccess: false,
    };
  },
  [EVENT_TYPES.GET_ALERT_SUCCESS]: (state, event) => {
    const { getAlertData } = event.payload;

    return {
      ...state,
      getAlertLoading: false,
      getAlertSuccess: true,
      alertDetailsData: getAlertData,
    };
  },

  [EVENT_TYPES.ACTIVE_ALERTS_LOADING]: (state, event) => {
    return {
      ...state,
      activeAlertsLoading: true,
      activeAlertsSuccess: false,
      error: "",
    };
  },
  [EVENT_TYPES.ACTIVE_ALERTS_SUCCESS]: (state, event) => {
    const { activeAlertData } = event.payload;

    return {
      ...state,
      activeAlertsLoading: false,
      activeAlertsSuccess: true,
      activeAlertsData: activeAlertData,
    };
  },

  [EVENT_TYPES.ACTIVE_ALERTS_ERROR]: (state, event) => {
    const { error } = event.payload;
    return {
      ...state,
      error: "",
      alertsListError: "",
      alertsListByTypeError: "",
      activeAlertsError: error,
      alertsListSuccess: false,
      alertsListLoading: false,
      activeAlertsSuccess: false,
      activeAlertsLoading: false,
      viewedAlertsLoading: false,
      viewedAlertsSuccess: false,
    };
  },

  [EVENT_TYPES.ERROR]: (state, event) => {
    const { error } = event.payload;
    return {
      ...state,
      error,
      alertsListError: "",
      alertsListByTypeError: "",
      activeAlertsError: "",
      alertsListSuccess: false,
      alertsListLoading: false,
      activeAlertsSuccess: false,
      activeAlertsLoading: false,
      alertDetailsData: {},
      getAlertLoading: false,
      getAlertSuccess: false,
      viewedAlertsLoading: false,
      viewedAlertsSuccess: false,
      alertsListByTypeSuccess: false,
      alertsListByTypeData: {},
      alertsListByTypeLoading: false,
      getAlertTypesLoading: false,
      getAlertTypesSuccess: false,
      getAlertTypesData: [],
    };
  },
  [EVENT_TYPES.CLEAR_ERRORS]: (state) => {
    return {
      ...state,
      error: "",
      alertsListError: "",
      alertsListByTypeError: "",
      activeAlertsError: "",
      alertsListSuccess: false,
      alertsListLoading: false,
      activeAlertsSuccess: false,
      activeAlertsLoading: false,
      alertDetailsData: {},
      getAlertLoading: false,
      getAlertSuccess: false,
      viewedAlertsLoading: false,
      viewedAlertsSuccess: false,
      viewedAlertsData: [],
      alertsListByTypeSuccess: false,
      alertsListByTypeData: {},
      alertsListByTypeLoading: false,
      getAlertTypesLoading: false,
      getAlertTypesSuccess: false,
      getAlertTypesData: [],
    };
  },
};

const INITIAL_STATE = {
  alertsListSuccess: false,
  alertsListLoading: false,
  alertsListData: {},
  alertsListByTypeSuccess: false,
  alertsListByTypeData: {},
  alertsListByTypeLoading: false,
  activeAlertsData: [],
  alertDetailsData: {},
  getAlertLoading: false,
  getAlertSuccess: false,
  getAlertTypesLoading: true,
  getAlertTypesSuccess: false,
  getAlertTypesData: [],
  activeAlertsSuccess: false,
  activeAlertsLoading: false,
  viewedAlertsLoading: false,
  viewedAlertsSuccess: false,
  viewedAlertsData: [],
  activeAlertsError: "",
  alertsListError: "",
  alertsListByTypeError: "",
  error: "",
};

const generateNewCachedAlertsList = (data) => {
  let newData = {
    alerts: [...data],
  };
  let cachedActiveAlerts = [];
  if (localStorage.getItem("activeAlerts") !== undefined && localStorage.getItem("activeAlerts") !== null) {
    cachedActiveAlerts = JSON.parse(localStorage.getItem("activeAlerts"));
  }
  // Modify response to add the new "viewed" prop
  newData.alerts = newData.alerts.map((item) => {
    let viewedStatus = true;
    cachedActiveAlerts.forEach((activeItem) => {
      if (activeItem.alertId === item.alertId) {
        viewedStatus = false;
      }
    });
    let newItem = {
      ...item,
      viewed: viewedStatus,
    };
    return newItem;
  });

  // Store new value in localstorage, since this is where all data will be read from on the site
  localStorage.setItem("alertsList", JSON.stringify(newData));
  return newData;
};

const generateNewCachedAlertData = () => {};

const AlertsReducer = (state, event) => {
  return EVENTS[event.type](state, event) || state;
};

const AlertsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AlertsReducer, INITIAL_STATE);

  const handleUpdateViewedAlerts = (viewedAlertsData) => {
    const currentViewedAlertsList = JSON.parse(localStorage.getItem("viewedAlerts")) || [];
    let updatedViewedAlertsList = [...currentViewedAlertsList, ...viewedAlertsData];
    dispatch({ type: EVENT_TYPES.VIEWED_ALERTS_UPDATE, payload: { updatedViewedAlertsList } });
  };
  const handleGetAlertTypes = () => {
    dispatch({ type: EVENT_TYPES.GET_ALERT_TYPES_LOADING });
    AlertsService.getAlertTypes()
      .then((getAlertTypesData) => {
        // Get active Alerts and update cached alerts list with latest values
        dispatch({ type: EVENT_TYPES.GET_ALERT_TYPES_SUCCESS, payload: { getAlertTypesData } });
        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ALERTS_LIST_ERROR,
          payload: {
            error: message,
          },
        });
      });
  };
  const handleGetAlertsList = () => {
    dispatch({ type: EVENT_TYPES.ALERTS_LIST_LOADING });
    AlertsService.getAlertsList()
      .then((data) => {
        // Get active Alerts and update cached alerts list with latest values
        dispatch({ type: EVENT_TYPES.ACTIVE_ALERTS_LOADING });
        AlertsService.getActiveAlerts()
          .then((activeAlertData) => {
            // Store active alerts in local storage
            updateCachedActiveAlerts(activeAlertData);
            dispatch({ type: EVENT_TYPES.ACTIVE_ALERTS_SUCCESS, payload: { activeAlertData } });

            let newData = generateNewCachedAlertsList(data);
            dispatch({ type: EVENT_TYPES.ALERTS_LIST_SUCCESS, payload: { newData } });
            return Promise.resolve();
          })
          .catch(({ message }) => {
            dispatch({
              type: EVENT_TYPES.ACTIVE_ALERTS_ERROR,
              payload: {
                error: message,
              },
            });
          });

        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ALERTS_LIST_ERROR,
          payload: {
            error: message,
          },
        });
      });
  };
  const handleGetAlertsListByAlertType = () => {
    dispatch({ type: EVENT_TYPES.ALERTS_LIST_BY_TYPE_LOADING });
    AlertsService.getAlertsListByAlertType()
      .then((data) => {
        // Get active Alerts and update cached alerts list with latest values
        dispatch({ type: EVENT_TYPES.ACTIVE_ALERTS_LOADING });
        AlertsService.getActiveAlerts()
          .then((activeAlertData) => {
            // Store active alerts in local storage
            updateCachedActiveAlerts(activeAlertData);
            dispatch({ type: EVENT_TYPES.ACTIVE_ALERTS_SUCCESS, payload: { activeAlertData } });
            let newData = generateNewCachedAlertsList(data);
            dispatch({ type: EVENT_TYPES.ALERTS_LIST_BY_TYPE_SUCCESS, payload: { newData } });
            return Promise.resolve();
          })
          .catch(({ message }) => {
            dispatch({
              type: EVENT_TYPES.ACTIVE_ALERTS_ERROR,
              payload: {
                error: message,
              },
            });
          });

        return Promise.resolve();
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ALERTS_LIST_ERROR,
          payload: {
            error: message,
          },
        });
      });
  };
  const handleGetAlert = (alertId) => {
    /* REMOVE THIS - FOR TESTING ONLY */
    dispatch({ type: EVENT_TYPES.GET_ALERT_LOADING });
    // setTimeout(() => {
    //   const newAlertsData = {
    //     alerts: alerts,
    //   };
    //   dispatch({ type: EVENT_TYPES.SUCCESS, payload: { newAlertsData } });
    //   return Promise.resolve();
    // }, 500);
    /*********************************/

    AlertsService.getAlert(alertId)
      .then((getAlertData) => {
        // getActive Alerts

        // setTimeout(() => {
        dispatch({ type: EVENT_TYPES.GET_ALERT_SUCCESS, payload: { getAlertData } });
        return Promise.resolve();
        // }, 500);
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ALERTS_LIST_ERROR,
          payload: {
            error: message,
          },
        });
      });
  };
  const handleGetActiveAlerts = (callback) => {
    /* REMOVE THIS - FOR TESTING ONLY */
    dispatch({ type: EVENT_TYPES.ACTIVE_ALERTS_LOADING });

    AlertsService.getActiveAlerts()
      .then((activeAlertData) => {
        setTimeout(() => {
          // Store active alerts in local storage
          updateCachedActiveAlerts(activeAlertData);
          dispatch({ type: EVENT_TYPES.ACTIVE_ALERTS_SUCCESS, payload: { activeAlertData } });
          return Promise.resolve();
        }, 500);
      })
      .catch(({ message }) => {
        dispatch({
          type: EVENT_TYPES.ACTIVE_ALERTS_ERROR,
          payload: {
            error: message,
          },
        });
      });
  };

  const handleMarkAlertAsViewed = (alertItem) => {
    // console.log("Alert marked as read: ", alertItem);
  };

  const handleError = (errorMsg) => {
    dispatch({
      type: EVENT_TYPES.ERROR,
      payload: {
        error: errorMsg,
      },
    });
  };
  const handleClearErrors = () => {
    dispatch({ type: EVENT_TYPES.CLEAR_ERRORS });
  };

  const events = {
    onError: handleError,
    onGetAlertsList: handleGetAlertsList,
    onGetAlertTypes: handleGetAlertTypes,
    onGetAlertsListByAlertType: handleGetAlertsListByAlertType,
    onGetAlert: handleGetAlert,
    onGetActiveAlerts: handleGetActiveAlerts,
    onMarkAlertAsViewed: handleMarkAlertAsViewed,
    onUpdateViewedAlerts: handleUpdateViewedAlerts,
    onClearErrors: handleClearErrors,
  };

  return (
    <AlertsState.Provider value={state}>
      <AlertsDispatch.Provider value={events}>{children}</AlertsDispatch.Provider>
    </AlertsState.Provider>
  );
};

const useAlertsState = () => {
  const context = useContext(AlertsState);

  if (context === undefined) {
    throw new Error("useAlertsState must be used within an AlertsProvider");
  }

  return context;
};

const useAlertsDispatch = () => {
  const context = useContext(AlertsDispatch);

  if (context === undefined) {
    throw new Error("useAlertsDispatch must be used within an AlertsProvider");
  }

  return context;
};

export { AlertsProvider, useAlertsState, useAlertsDispatch };
