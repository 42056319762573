import { deepMergeArrays } from "./deepMergeArrays";
import dayjs from "dayjs";
const sanitizeActiveAlerts = (activeAlertsListDataArray) => {
  let currentData = activeAlertsListDataArray;

  let cachedViewedAlerts = JSON.parse(localStorage.getItem("viewedAlerts")) || [];

  // compare active alerts list with viewed list. Remove any matching alert IDs
  // found in the viewed list from the final array.
  currentData = currentData.filter((item) => {
    return !cachedViewedAlerts.some((viewedItem) => viewedItem.alertId === item.alertId);
  });

  let finishedAlert = {};
  // check if there is more than one active alert
  if (currentData.length === 0) {
    return finishedAlert;
  }
  if (currentData.length > 1) {
    // show grouped version of notification.
    finishedAlert = {
      severity: "info",
      message: `IHSI released ${currentData.length} new versions`,
      duration: "30000",
      url: "/allreleases",
    };
  } else {
    // 'default'
    // | 'primary'
    // | 'secondary'
    // | 'error'
    // | 'info'
    // | 'success'
    // | 'warning'
    // | string
    let severityColor = "default";

    switch (currentData[0].severity.toLowerCase()) {
      case "critical":
        // code to execute if expression === value1
        severityColor = "error";
        break;
      case "warning":
        // code to execute if expression === value2
        severityColor = "warning";
        break;
      case "information":
        severityColor = "info";
        break;
      case "info":
        severityColor = "info";
        break;
      case "success":
        severityColor = "success";
        break;
      default:
        severityColor = "info";
    }

    finishedAlert = {
      severity: severityColor,
      //message: `${currentData[0].title} - ${currentData[0].summary}`,
      message: `${currentData[0].title} has been released`,
      duration: currentData[0].popupDuration,
      //url: `/releases/${currentData[0].alertId}`,
      url: "/allreleases",
    };
  }

  // Check user roles and compare with whitelist alert roles

  // Check current date and compare with notify date

  // Check if alert is enabled

  // Check if more than one alert is enabled

  // add cookies for blacklisted alerts to suppress them from showing up.
  return finishedAlert;
};

const updateCachedActiveAlerts = (activeAlertsData) => {
  // Store all active alerts in localstorage.
  // Overwrite existing active alerts in case users updated them on the server.
  //   let currentActiveAlerts = [];
  //   if (localStorage.getItem("activeAlerts") !== undefined && localStorage.getItem("activeAlerts") !== null) {
  //     currentActiveAlerts = JSON.parse(localStorage.getItem("activeAlerts"));
  //   }
  //   let newActiveAlerts = activeAlertsData.map((item) => {
  //     let newItem = {
  //       ...item,
  //       viewed: false,
  //     };
  //     return newItem;
  //   });
  // Combine old and new active alerts, overwriting props if needed in case the user modified an existing alert
  // deepMergeArrays also removes any expired arrays
  //const combinedActiveAlerts = deepMergeArrays(currentActiveAlerts, newActiveAlerts);

  // Remove alertIDs that no longer match

  // Store the final array in localstorage
  //localStorage.setItem("activeAlerts", JSON.stringify(combinedActiveAlerts));
  localStorage.setItem("activeAlerts", JSON.stringify(activeAlertsData));
};

const removeExpiredOrDeletedViewedAlerts = () => {
  //console.log("Removing expired or deleted viewed alerts");
};

const checkAndRemoveExpiredViewedAlerts = (alertsArray) => {
  //console.log("Checking for expired viewed alerts");
  const oneDayPast = dayjs().subtract(1, "day");
  let cleanedAlertsArray = alertsArray.filter((alert) => dayjs(alert.expireDate).isAfter(oneDayPast));
  localStorage.setItem("viewedAlerts", JSON.stringify(cleanedAlertsArray));
  return cleanedAlertsArray;
};
export {
  sanitizeActiveAlerts,
  updateCachedActiveAlerts,
  removeExpiredOrDeletedViewedAlerts,
  checkAndRemoveExpiredViewedAlerts,
};
